<script setup lang="ts">
useHead({
  title: 'MetalMusicMadness'
});

const layoutLoaded = ref(false)

const authToken = useState('authToken', () => null)
const showAlertSwitch = useState('showAlert', () => false)
const alertMsg = useState('alertMsg', () => '')

onMounted(async () => {
  layoutLoaded.value = authToken.value !== null
})
</script>

<template>
  <v-layout class="rounded">
    <v-main class="d-flex align-center justify-center my-2">
      <v-card class="flex-fill mx-2">
        <NuxtPage />
        <v-snackbar
            color="primary"
            v-model="showAlertSwitch"
            :timeout="2000"
        >
          {{ alertMsg }}

          <template v-slot:actions>
            <v-btn
                icon="mdi-close"
                @click="showAlertSwitch = false"
            >

            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-main>
  </v-layout>
</template>

<style scoped>

</style>